/* eslint-disable react-hooks/rules-of-hooks */
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useRef } from 'react';
import type { Location, Router, RouterProviderProps } from 'react-router-dom';
import { useInRouterContext, useLocation as useReactRouterLocation } from 'react-router-dom';
import { ROUTER, stripBaseName } from 'ts/base/routing/Router';
import { ObjectUtils } from 'ts/commons/ObjectUtils';
import type { StoreApi, UseBoundStore } from 'zustand';
import { create } from 'zustand';

/** Special state that signals that the location change should not cause the view to be re-created. */
export const DO_NOT_RELOAD_VIEW_STATE = 'do-not-reload-the-view-for-this-change';

/**
 * Returns the artificial location of the current page.
 *
 * @param selector Allows to extract a subset of the location that the component is interested in. The component will
 *   only re-render when the value returned from the selector function has changed.
 */
export function useLocation<T>(selector: (location: Location) => T): T {
	// For components that already live inside the root React tree use React Router
	if (useInRouterContext()) {
		return selector(useReactRouterLocation());
	}
	// For components that are rendered from a TeamscaleViewBase via ReactUtils and hence live outside the root tree
	return useContext(StaticLocationContext)!(({ location }) => selector(location));
}

type Router = RouterProviderProps['router'];
type RouterState = Router['state'];
export type HistoryAction = RouterState['historyAction'];

/** Type of the state stored in the store of StaticLocationContext. */
type StaticLocationState = { location: Location };

/**
 * Re-renders only when the navigation was triggered via NavigationHash#applyUrlWithoutReload, but should not receive
 * updates that will trigger a re-load of the whole view anyway, and therefore certain invariants would no longer hold.
 * This is the default in components that are mounted via ReactUtils.render/append as part of a view.
 */
export const StaticLocationContext = createContext<UseBoundStore<StoreApi<StaticLocationState>> | undefined>(undefined);

type StaticLocationContextRef = {
	store: UseBoundStore<StoreApi<StaticLocationState>>;
	disableUpdate: boolean;
	historyAction: HistoryAction;
};

/**
 * Re-renders only when the navigation was triggered via NavigationHash#applyUrlWithoutReload, but should not receive
 * updates that will trigger a re-load of the whole view anyway, and therefore certain invariants would no longer hold.
 * This is the default in components that are mounted via ReactUtils.render/append as part of a view.
 */
export function StaticLocationContextProvider({ children }: { children: ReactNode }) {
	const ref = useRef<StaticLocationContextRef>();
	if (!ref.current) {
		const initialLocation = stripBaseName(ROUTER.state.navigation.location ?? ROUTER.state.location);
		const historyAction = ROUTER.state.historyAction;
		ref.current = {
			disableUpdate: false,
			historyAction,
			store: create<StaticLocationState>(() => ({
				location: initialLocation
			}))
		};
	}

	useEffect(
		() =>
			ROUTER.subscribe(({ historyAction, location }) => {
				const localRefs = ref.current;
				if (!localRefs || localRefs.disableUpdate) {
					return;
				}
				localRefs.store.setState(oldState => {
					const isSilentUrlChange =
						location.state === DO_NOT_RELOAD_VIEW_STATE && historyAction === 'REPLACE';
					const oldLatestLocation = oldState.location;
					const newLocation = stripBaseName(location);
					const locationHasChanged = !ObjectUtils.deepEqual(oldLatestLocation, newLocation);
					if (!locationHasChanged && localRefs.historyAction === historyAction) {
						return {};
					}
					if (!isSilentUrlChange) {
						localRefs.disableUpdate = true;
						return {};
					}
					return { location: newLocation };
				});
			}),
		[]
	);
	return <StaticLocationContext.Provider value={ref.current.store}>{children}</StaticLocationContext.Provider>;
}
