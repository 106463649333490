import type { Location } from 'react-router-dom';
import { Assertions } from 'ts/commons/Assertions';
import { NavigationHash } from './../NavigationHash';
import { StringUtils } from './../StringUtils';
import { CodeLineSelection } from './CodeLineSelection';
import { CodeOffsetSelection } from './CodeOffsetSelection';
import { CodeSelection } from './CodeSelection';

/** Utility class for creating CodeSelection objects. */
export class CodeSelectionUtils {
	/**
	 * Factory method for parsing a CodeSelection from a given NavigationHash. Supports character based and line based
	 * region descriptions "char-1040-1550" or "5-10".
	 *
	 * @returns If applicable, a suiting selection object is returned, otherwise null
	 */
	public static fromNavigationHash(navigationHash: NavigationHash): CodeLineSelection | CodeOffsetSelection | null {
		const selectionParameterValue = navigationHash.getString(NavigationHash.SELECTION_PARAMETER);
		return CodeSelectionUtils.parseCodeSelection(selectionParameterValue);
	}

	/**
	 * Factory method for parsing a CodeSelection from a given Location. Supports character based and line based region
	 * descriptions "char-1040-1550" or "5-10".
	 *
	 * @returns If applicable, a suiting selection object is returned, otherwise null
	 */
	public static fromLocation(location: Location): CodeLineSelection | CodeOffsetSelection | null {
		const selectionParameterValue = new URLSearchParams(location.search).get(NavigationHash.SELECTION_PARAMETER);
		return CodeSelectionUtils.parseCodeSelection(selectionParameterValue);
	}

	/**
	 * Factory method for parsing a CodeSelection from a string. Supports character based and line based region
	 * descriptions "char-1040-1550" or "5-10".
	 *
	 * @returns If applicable, a suiting selection object is returned, otherwise null
	 */
	public static parseCodeSelection(
		selectionString: string | null | undefined
	): CodeLineSelection | CodeOffsetSelection | null {
		if (StringUtils.isEmptyOrWhitespace(selectionString)) {
			return null;
		}
		selectionString = Assertions.assertString(selectionString).trim();
		let isOffsetBased = false;
		if (selectionString.startsWith(CodeSelection.OFFSET_INDICATOR)) {
			isOffsetBased = true;
			selectionString = StringUtils.stripPrefix(selectionString, CodeSelection.OFFSET_INDICATOR);
		}
		const selectionParameterValues = StringUtils.splitNotEmpty(
			selectionString,
			CodeSelection.URL_PARAMETER_SEPARATOR
		);
		if (selectionParameterValues.length < 1 || selectionParameterValues.length > 2) {
			return null;
		}
		const firstParameterValue = Number(selectionParameterValues[0]!);
		if (isNaN(firstParameterValue) || StringUtils.isEmptyOrWhitespace(selectionParameterValues[0])) {
			return null;
		}
		let secondParameterValue = firstParameterValue;
		if (selectionParameterValues.length === 2) {
			secondParameterValue = Number(selectionParameterValues[1]!);
			if (isNaN(secondParameterValue) || StringUtils.isEmptyOrWhitespace(selectionParameterValues[1])) {
				return null;
			}
		}
		if (isOffsetBased) {
			return new CodeOffsetSelection(firstParameterValue, secondParameterValue);
		}
		return new CodeLineSelection(firstParameterValue, secondParameterValue);
	}

	/** Generates a URL including the given selection. */
	public static generateUrl(selection: CodeLineSelection): string {
		const newNavigationHash = NavigationHash.getCurrent();
		newNavigationHash.setSelection(selection);
		const hash = newNavigationHash.toString();
		return new URL(hash, window.location.href).toString();
	}
}
