import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { AntPatternIncludeExcludeSupport } from 'ts/commons/AntPatternIncludeExcludeSupport';
import type { ExtendTypeWith } from 'ts/commons/ExtendTypeWith';
import { MarkdownUtils } from 'ts/commons/markdown/MarkdownUtils';
import type { DetachedFinding } from 'ts/data/DetachedFinding';
import type { ExtendedTrackedFinding } from 'typedefs/ExtendedTrackedFinding';
import type { FindingBlacklistInfo } from 'typedefs/FindingBlacklistInfo';
import type { TrackedFinding } from 'typedefs/TrackedFinding';

/** Extensions for a TrackedFinding. */
type TrackedFindingExtension = {
	commit?: UnresolvedCommitDescriptor;
	voteIncludeExcludePattern?: AntPatternIncludeExcludeSupport;
};

/** Extended version of TrackedFinding. */
export type ExtendedTrackedFindingVoting = ExtendTypeWith<ExtendedTrackedFinding, TrackedFindingExtension>;

/** Extends a list of TrackedFindings setting their commits, blacklistInfos and typeNames. */
export function wrapFindings(
	findings: TrackedFinding[],
	findingTypeIdToTypeNameLookup: Map<string, string>,
	blacklistInfoLookup: Map<string, FindingBlacklistInfo>,
	commitToSetOnFindings?: UnresolvedCommitDescriptor,
	voteIncludeExcludePattern?: AntPatternIncludeExcludeSupport
): ExtendedTrackedFindingVoting[] {
	return findings.map(finding =>
		wrapFinding(
			finding,
			findingTypeIdToTypeNameLookup,
			blacklistInfoLookup,
			commitToSetOnFindings,
			voteIncludeExcludePattern
		)
	);
}

/**
 * Extends a TrackedFinding to match the fields of a ExtendedTrackedFinding and additionally voteIncludeExcludePattern
 * and commit.
 */
function wrapFinding(
	finding: TrackedFinding,
	findingTypeIdToTypeNameLookup: Map<string, string>,
	blacklistInfoLookup: Map<string, FindingBlacklistInfo>,
	commitToSetOnFindings?: UnresolvedCommitDescriptor,
	voteIncludeExcludePattern?: AntPatternIncludeExcludeSupport
): ExtendedTrackedFindingVoting {
	return Object.assign(finding, {
		tasksByStatus: {},
		blacklistInfo: blacklistInfoLookup.get(finding.id),
		commit: commitToSetOnFindings,
		typeName: findingTypeIdToTypeNameLookup.get(finding.typeId)!,
		voteIncludeExcludePattern
	});
}

/** Renders markdown in finding messages of tasks by setting the 'renderedMessage' property */
export function renderMarkdownInFindingMessages<T extends Pick<DetachedFinding, 'message'>>(findings: T[]): T[] {
	for (const finding of findings) {
		renderMarkdownInFindingMessage(finding);
	}
	return findings;
}

/** Renders markdown in finding message of tasks by setting the 'renderedMessage' property */
export function renderMarkdownInFindingMessage<T extends Pick<DetachedFinding, 'message'>>(finding: T): T {
	Object.assign(finding, {
		renderedMessage: MarkdownUtils.renderToSafeHtml(finding.message, {}, true)
	});
	return finding;
}
