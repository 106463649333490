import { QUERY } from 'api/Query';
import type { JSX } from 'react';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { DEFAULT_STALE_TIME } from 'ts/base/perspective/topbar/warnings/GlobalWarnings';

/** Informs the user that shadow mod is enabled. */
export function useLegacyApiWarning(): JSX.Element | null {
	const { data: legacyApiUsed } = QUERY.wasLegacyApiUsed().useQuery({
		staleTime: DEFAULT_STALE_TIME,
		refetchOnReconnect: 'always'
	});

	if (!legacyApiUsed) {
		return null;
	}
	return (
		<GlobalWarning
			message="Legacy API in use"
			severity="warning"
			tooltip="Legacy API is deprecated. It will be disabled by default with Teamscale v10.0 and removed in a future version. Please check the service log for the actual use, and the API reference for alternatives."
		/>
	);
}
