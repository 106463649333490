import type { ColumnDef, OnChangeFn, RowData, SortingState, Table, TableOptions } from '@tanstack/react-table';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { type JSX, useMemo } from 'react';
import type { Callback } from 'ts/base/Callback';
import type { TableBaseProps } from 'ts/base/table/Table';
import { Table as TableComponent } from 'ts/base/table/Table';
import { ESortOrder } from 'typedefs/ESortOrder';

/**
 * To effectively disable pagination, we use a very huge integer as page size. To avoid risking overflows we're not
 * using {@link Number#MAX_SAFE_INTEGER} directly.
 */
export const MAX_TABLE_SIZE = Math.round(Number.MAX_SAFE_INTEGER / 2);

/**
 * Internal value used as page size for showing all values without pagination. Used in the hash state but not passed to
 * the tanstack table.See {@link #MAX_TABLE_SIZE}.
 */
export const NO_PAGING_PAGE_SIZE = -1;

type SortableTableOptions<T extends RowData, TValue = unknown> = {
	columnDefinitions: Array<ColumnDef<T, TValue>>;
	data: T[];
	sortOptions?: SortOptions;
	onSortOptionsChanged?: Callback<SortOptions>;
	tableOptions?: Partial<TableOptions<T>>;
};

/** Props for SortableTable. */
export type SortableTableProps<T extends RowData, TValue = unknown> = Omit<TableBaseProps<T>, 'sortable'> &
	SortableTableOptions<T, TValue>;

/** Props for the sortOrder state. */
export type SortOptions = {
	sortByField: string;
	sortOrder: ESortOrder;
};

/** Converts our own sort options into the format React Table expects to receive as initialState. */
export function convertToReactTableSortBy(id: string | undefined, sortOrder: ESortOrder | undefined): SortingState {
	if (id === undefined || sortOrder === undefined) {
		return [];
	}
	const desc = sortOrder === ESortOrder.DESCENDING;
	return [{ id, desc }];
}

/**
 * Provides a table instance that is configured to sort its content. In contrast to the SortableTable component the hook
 * allows you to customize the returned table as needed before passing it to the Table component for rendering.
 */
export function useSortableTable<T extends RowData, TValue = unknown>({
	columnDefinitions,
	data,
	sortOptions,
	tableOptions = {}
}: SortableTableOptions<T, TValue>): Table<T> {
	const sorting = useMemo(
		() => convertToReactTableSortBy(sortOptions?.sortByField, sortOptions?.sortOrder),
		[sortOptions?.sortByField, sortOptions?.sortOrder]
	);
	return useReactTable({
		data,
		columns: columnDefinitions,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableSorting: true,
		enableSortingRemoval: true,
		...tableOptions,
		initialState: {
			sorting,
			...tableOptions.initialState
		}
	});
}

/**
 * Provides a table instance that is configured to sort its content. In contrast to the SortableTable component the hook
 * allows you to customize the returned table as needed before passing it to the Table component for rendering.
 */
export function useSortableTableWithCallback<T extends RowData, TValue = unknown>({
	columnDefinitions,
	data,
	sortOptions,
	onSortOptionsChanged,
	tableOptions = {}
}: SortableTableOptions<T, TValue>): Table<T> {
	const sorting = useMemo(
		() => convertToReactTableSortBy(sortOptions?.sortByField, sortOptions?.sortOrder),
		[sortOptions?.sortByField, sortOptions?.sortOrder]
	);
	return useReactTable({
		data,
		columns: columnDefinitions,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		onSortingChange: getOnSortOptionsChanged(sorting, onSortOptionsChanged),
		enableSorting: true,
		enableSortingRemoval: true,
		...tableOptions,
		state: {
			sorting,
			...tableOptions.state
		},
		initialState: {
			...tableOptions.initialState
		}
	});
}

export function getOnSortOptionsChanged(
	sorting: SortingState,
	onSortOptionsChanged: Callback<SortOptions> | undefined
): OnChangeFn<SortingState> | undefined {
	return sortState => {
		let sortBy: SortingState;
		if (typeof sortState === 'function') {
			sortBy = sortState(sorting);
		} else {
			sortBy = sortState;
		}
		if (onSortOptionsChanged) {
			const sortData = sortBy[0]!;
			onSortOptionsChanged({
				sortByField: sortData.id,
				sortOrder: sortData.desc ? ESortOrder.DESCENDING : ESortOrder.ASCENDING
			});
		}
	};
}

/** A table that is automatically sortable. All data must be available on the client side. */
export function SortableTable<T extends RowData, TValue = unknown>({
	columnDefinitions,
	data,
	sortOptions,
	tableOptions,
	...tableProps
}: SortableTableProps<T, TValue>): JSX.Element {
	const table = useSortableTable({
		sortOptions,
		columnDefinitions,
		data,
		tableOptions
	});
	return <TableComponent sortable table={table} {...tableProps} />;
}
