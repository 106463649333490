import { Menu } from '@mantine/core';
import clsx from 'clsx';
import { type ComponentPropsWithoutRef, forwardRef, type JSX, useState } from 'react';
import { Divider, Icon, type SemanticICONS } from 'semantic-ui-react';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { Assertions } from 'ts/commons/Assertions';
import { linkTo } from 'ts/commons/links/LinkTo';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import type { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Props for ActivePerspectiveSubViewMenu and PerspectiveSubViewPopupMenu. */
type PerspectiveSubViewMenuProps = {
	views: ViewDescriptor[];
	perspective: ETeamscalePerspective;
	perspectiveLink: string;
};

/** Creates the menu for switching views within a perspective from an expanded sidebar. */
export function ActivePerspectiveSubViewMenu({
	views,
	perspective,
	perspectiveLink
}: PerspectiveSubViewMenuProps): JSX.Element | null {
	const hash = useNavigationHash();
	const currentView = hash.getViewName();
	Assertions.assertExists(currentView);
	if (views.length === 0) {
		return <PerspectiveLinkSidebarEntry perspective={perspective} active to={perspectiveLink} />;
	}

	const activeSubview = PerspectiveViewDescriptorBase.findViewDescriptor(
		views,
		currentView,
		hash.getAction() ?? undefined
	);
	return (
		<>
			<PerspectiveLinkSidebarEntry perspective={perspective} active to={perspectiveLink} />
			<div id="sub-menu-container" className="ui secondary left pointing vertical menu" style={{ width: '100%' }}>
				{views.map(view => (
					<div key={view.anchor} className="item-wrapper">
						<SidebarSubViewMenuEntry
							perspective={perspective}
							view={view}
							isActive={view.anchor === activeSubview?.anchor}
							hash={hash}
						/>
					</div>
				))}
			</div>
		</>
	);
}

/**
 * Creates the perspective link with a floating menu for switching views within a non-active perspective or from a
 * collapsed sidebar.
 */
export function PerspectiveSubViewPopupMenu({
	views,
	perspectiveLink,
	perspective
}: PerspectiveSubViewMenuProps): JSX.Element | null {
	const hash = useNavigationHash();
	const currentView = hash.getViewName();
	Assertions.assertExists(currentView);
	const [opened, setOpened] = useState(false);
	const activePerspective = hash.getPerspective();
	const isActivePerspective = perspective.name === activePerspective.name;
	if (views.length === 0) {
		return (
			<PerspectiveLinkSidebarEntry perspective={perspective} active={isActivePerspective} to={perspectiveLink} />
		);
	}

	const activeSubview = PerspectiveViewDescriptorBase.findViewDescriptor(
		views,
		currentView,
		hash.getAction() ?? undefined
	);
	return (
		<Menu
			unstyled
			trigger="hover"
			openDelay={100}
			closeDelay={100}
			position="right-start"
			offset={0}
			opened={opened}
			onChange={setOpened}
		>
			<Menu.Target>
				<PerspectiveLinkSidebarEntry
					perspective={perspective}
					active={opened || isActivePerspective}
					to={perspectiveLink}
				/>
			</Menu.Target>
			<Menu.Dropdown className="right top ui popup vertical borderless menu navigation-popup">
				<Menu.Item
					component={TeamscaleLink}
					className="item"
					to={perspectiveLink}
					data-testid={`popup-${perspective.simpleName}-link`}
				>
					<strong>{perspective.displayName}</strong>
				</Menu.Item>
				<Divider className="marginless min-w-[15rem]" />
				{views.map(view => (
					<Menu.Item
						component={SidebarSubViewMenuEntry}
						key={view.anchor}
						view={view}
						isActive={view.anchor === activeSubview?.anchor}
						hash={hash}
						className="tooltip-item-list perspective-link"
						perspective={perspective}
					/>
				))}
			</Menu.Dropdown>
		</Menu>
	);
}

type PerspectiveLinkSidebarEntryProps = ComponentPropsWithoutRef<'a'> & {
	perspective: ETeamscalePerspective;
	active: boolean;
	to: string;
};

const PerspectiveLinkSidebarEntry = forwardRef<HTMLAnchorElement, PerspectiveLinkSidebarEntryProps>(
	function PerspectiveLinkSidebarEntry({ perspective, active, to, ...props }: PerspectiveLinkSidebarEntryProps, ref) {
		// We don't want onClick to trigger the popup menu
		const { onClick, ...restProps } = props;
		return (
			<TeamscaleLink
				{...restProps}
				ref={ref}
				id={'link-' + perspective.simpleName}
				className={clsx('perspective-link item', { active })}
				to={to}
			>
				<div className="item-wrapper">
					{perspective.iconClass ? <Icon name={perspective.iconClass as SemanticICONS} /> : null}
					<span className="item__text">{perspective.displayName}</span>
				</div>
			</TeamscaleLink>
		);
	}
);

type SidebarSubViewMenuEntryProps = ComponentPropsWithoutRef<'a'> & {
	view: ViewDescriptor;
	isActive: boolean;
	hash: NavigationHash;
	perspective: ETeamscalePerspective;
};

/** Renders the link for a specific subview either in the sidebar or the sidebar's floating menu. */
const SidebarSubViewMenuEntry = forwardRef<HTMLAnchorElement, SidebarSubViewMenuEntryProps>(
	function SidebarSubViewMenuEntry(
		{ view, isActive, hash, perspective, className, ...props }: SidebarSubViewMenuEntryProps,
		ref
	) {
		return (
			<TeamscaleLink
				ref={ref}
				data-name={view.name}
				className={clsx(className, 'item', 'view-switcher-' + view.anchor, {
					active: isActive
				})}
				to={getLinkToPerspective(perspective, view, hash)}
				{...props}
			>
				{view.name}
				{Boolean(view.isBeta) && <span className="beta-label">Beta</span>}
			</TeamscaleLink>
		);
	}
);

/**
 * Returns the link to the view on the given perspective taking the #keepPathAndArgumentsOfCurrentViewForSubviews into
 * account.
 */
export function getLinkToPerspective(
	perspective: ETeamscalePerspective,
	viewDescriptor: ViewDescriptor,
	currentHash: NavigationHash
): string {
	return (
		linkTo(
			perspective,
			viewDescriptor,
			// Links to project-specific perspectives or to the Dashboard perspective should keep the currently selected project (if available)
			viewDescriptor.requiresProject ? currentHash.getProject() : undefined
		) +
		(viewDescriptor.keepPathAndArgumentsOfCurrentViewForSubviews
			? '/' + currentHash.getProjectAndPath().getPath() + currentHash.getArgumentsString()
			: '')
	);
}
