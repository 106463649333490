import { QUERY } from 'api/Query';
import { QUERY_CLIENT } from 'api/QueryClient';
import type { LoaderFunctionArgs, Location, RouteObject, RouterProviderProps } from 'react-router-dom';
import { createBrowserRouter, redirect, useRouteError } from 'react-router-dom';
import { PerspectiveSettingsBarSkeleton } from 'ts/base/perspective/topbar/PerspectiveSettingsBar';
import { TeamscalePerspectiveRoot } from 'ts/base/TeamscalePerspectiveRoot';
import { TeamscaleViewRoot } from 'ts/base/TeamscaleViewRoot';
import { teamscaleViewRootLoader } from 'ts/base/TeamscaleViewRoot.loader';
import { DECLARATION_OF_ACCESSIBILITY_LINK } from 'ts/commons/accessibility/DeclarationOfAccessibility';
import { ErrorFallback } from 'ts/commons/ErrorFallback';
import { StringUtils } from 'ts/commons/StringUtils';
import { NotFound } from 'ts/NotFoundPage';
import { PERSPECTIVES } from 'ts/Perspectives';
import { TeamscaleLoginPage } from 'ts/perspectives/login/TeamscaleLoginPage';

const ROUTES: RouteObject[] = [
	{
		path: '/',
		errorElement: <RootErrorComponent />,
		children: [
			{
				path: '',
				loader: () => redirect('/dashboard/show')
			},
			{
				path: '/',
				element: <TeamscalePerspectiveRoot />,
				children: PERSPECTIVES.map(perspectiveDescriptor => ({
					path: perspectiveDescriptor.perspective.simpleName + '/*',
					HydrateFallback: PerspectiveSettingsBarSkeleton,
					loader: (args: LoaderFunctionArgs) => teamscaleViewRootLoader(args, perspectiveDescriptor),
					element: <TeamscaleViewRoot key={perspectiveDescriptor.perspective.name} />,
					shouldRevalidate: ({ defaultShouldRevalidate }) => {
						const wasSilentUpdate = NEXT_NAVIGATION.isSilent;
						return !wasSilentUpdate && defaultShouldRevalidate;
					}
				}))
			}
		]
	},
	{
		path: DECLARATION_OF_ACCESSIBILITY_LINK,
		lazy: () =>
			import('../../perspectives/accessibility/DeclarationOfAccessibilityPage').then(
				({ default: Component }) => ({ Component })
			)
	},
	{
		path: 'login/*',
		element: <TeamscaleLoginPage />,
		loader: () => {
			QUERY_CLIENT.clear();
			QUERY_CLIENT.prefetchQuery(QUERY.getLoginContext().query());
			return null;
		}
	},
	{
		path: '*',
		element: <NotFound />
	}
];

/** The path prefix under which the Teamscale instance is exposed to the user or an empty string if there is no prefix. */
export const BASE_NAME = StringUtils.stripSuffix(new URL(document.head.baseURI).pathname, '/');

/** The router object for Teamscale. */
export const ROUTER: RouterProviderProps['router'] = createBrowserRouter(ROUTES, {
	basename: BASE_NAME
});

/** Strips the url prefix/basename from the location. */
export function stripBaseName(location: Location): Location {
	return {
		...location,
		pathname: StringUtils.stripPrefix(location.pathname, BASE_NAME)
	};
}

function RootErrorComponent(): JSX.Element {
	return <ErrorFallback error={useRouteError()} resetErrorBoundary={() => location.reload()} />;
}

/** Signals that the next navigation is a silent update which should not cause the whole root data to be revalidated. */
export const NEXT_NAVIGATION: { isSilent: boolean } = { isSilent: false };
