import { ProjectResolver } from 'ts/base/ProjectResolver';
import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import { EQualityPerspectiveView } from 'ts/perspectives/quality_control/EQualityPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the quality control perspective. */
export class QualityControlPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.QUALITY_CONTROL, EQualityPerspectiveView);
	}

	/**
	 * We need to dynamically set the project to allow showing all reports when selecting "All projects" in the project
	 * selector.
	 */
	public override getProject(
		perspectiveContext: ExtendedPerspectiveContext,
		hash: NavigationHash,
		viewDescriptor: ViewDescriptor
	): string | null {
		const viewName = hash.getViewName();
		if (viewName === EQualityPerspectiveView.REPORTS.anchor) {
			const projectResolver = new ProjectResolver(perspectiveContext);
			return projectResolver.getProjectForDashboardOrReportsPerspective(hash);
		}
		return super.getProject(perspectiveContext, hash, viewDescriptor);
	}
}
