import * as strings from 'ts-closure-library/lib/string/string';
import { truncate, truncateMiddle } from 'ts-closure-library/lib/string/string';
import { Assertions } from 'ts/commons/Assertions';
import { ArrayUtils } from './ArrayUtils';

/** Utility methods for dealing with strings. */
export class StringUtils {
	/** The unicode character for three dots (also called 'horizontal ellipsis'). */
	public static UNICODE_DOTDOTDOT_CHARACTER = '\u2026';

	/** Splits a string into lines. */
	public static splitLines(string: string): string[] {
		return string.split('\n');
	}

	/** Behaves the same as String.split() except: If stringToSplit is empty it will return an empty array. */
	public static splitNotEmpty(stringToSplit: string, separator: string): string[] {
		if (stringToSplit.length > 0) {
			return stringToSplit.split(separator);
		}
		return [];
	}

	/** Return the value if it is not empty or null otherwise. */
	public static getNullIfEmpty(value: string): string | null {
		if (!value || value === '') {
			return null;
		}
		return value;
	}

	/** Return the value as string if it is non-null or undefined if it was null. */
	public static getStringFromNullableObject(
		variable: string | number | object | null | undefined
	): string | undefined {
		if (variable == null) {
			return undefined;
		} else {
			return String(variable);
		}
	}

	/**
	 * Replace all occurrences of the target with the replacement in the given string.
	 *
	 * @param string The string containing the target.
	 * @param target The targets to replace inside the string. Nothing is replaced if this is not set.
	 * @param replacement The replacement for the target occurrences. Defaults to the empty string if this is not set.
	 * @returns The string with all targets replaced. Null and undefined strings are preserved.
	 */
	public static replaceAll(string: string, target: string, replacement?: string): string;
	public static replaceAll(string: string | null, target: string, replacement?: string): string | null;
	public static replaceAll(string: string | null, target: string, replacement = ''): string | null {
		if (string === null) {
			return string;
		}
		const parts = string.split(target);
		return parts.join(replacement);
	}

	/**
	 * For every pair of strings, replace all occurrences of the first element in {@link string} with the second element.
	 * Elements are replaced in order -- be careful when there are dependencies between the pairs. I.e.
	 * <code>replaceAllUsingPairs('foo', [['f', 'o'], ['o', 'a']]) === 'aaa'</code>.
	 *
	 * @param string The string containing the first elements of the pairs.
	 * @param pairs A list of pairs of the form <s1,s2> indicating that all occurrences of s1 in {@link string} should be
	 *   replaced with s2.
	 * @returns The string with all targets replaced. Null and undefined strings are preserved.
	 */
	public static replaceAllUsingPairs(string: string, pairs: Array<[string, string]>): string;
	public static replaceAllUsingPairs(string: string | null, pairs: Array<[string, string]>): string | null {
		if (string === null) {
			return string;
		}
		return pairs.reduce(
			(result: string | null, pair: [string, string]) => StringUtils.replaceAll(result, pair[0], pair[1]),
			string
		);
	}

	/**
	 * @param string The input
	 * @returns The reverses string. Null and undefined strings are preserved.
	 */
	public static reverse(string: string): string;
	public static reverse(string: string | null | undefined): string | null | undefined;
	public static reverse(string: string | null | undefined): string | null | undefined {
		if (string === null || string === undefined) {
			return string;
		}
		return string.split('').reverse().join('');
	}

	/** Returns true if the given searchString is contained in any of the given possible strings (ignoring casing). */
	public static isContainedInAnyIgnoreCase(substring: string, ...possibleStrings: string[]): boolean {
		const substringLowerCase = substring.toLowerCase();
		return possibleStrings.some(string => string.toLowerCase().includes(substringLowerCase));
	}

	/** Checks if a string is empty or contains only whitespaces. */
	public static isEmptyOrWhitespace(string: string | null | undefined): string is '' | null | undefined {
		return string == null || string.trim() === '';
	}

	/**
	 * Checks if a string represents a numeric value. An empty string is not considered to be a valid number even if
	 * e.g. Number() would convert it to 0 without problems.
	 */
	public static isNumeric(str: string) {
		return !StringUtils.isEmptyOrWhitespace(str) && !isNaN(Number(str));
	}

	/**
	 * Concatenates the strings with an optional delimiter in between. Returns the empty string in case the strings
	 * array is empty.
	 */
	public static concat(strings: string[], delimiter = ''): string {
		if (ArrayUtils.isEmpty(strings)) {
			return '';
		}
		let current = strings[0]!;
		for (let i = 1; i < strings.length; i++) {
			current += delimiter;
			current += strings[i]!;
		}
		return current;
	}

	/** Adds prefix to string. Does nothing if the string already starts with the given prefix. */
	public static addPrefixWithoutDuplication(inputString: string, prefix: string): string {
		if (!inputString.startsWith(prefix)) {
			return prefix + inputString;
		}
		return inputString;
	}

	/**
	 * Removes prefix from a string.
	 *
	 * @param string String from which the prefix should be deleted
	 * @param prefix Prefix that should be removed from the given string
	 * @returns The string without the prefix or the original string if it does not start with the prefix.
	 */
	public static stripPrefix(string: string, prefix: string): string {
		if (string.startsWith(prefix)) {
			return string.substring(prefix.length);
		}
		return string;
	}

	/**
	 * Removes suffix from a string.
	 *
	 * @param string String from which the suffix should be deleted
	 * @param suffix Suffix that should be removed from the given string
	 * @returns The string without the suffix or the original string if it does not end with the suffix.
	 */
	public static stripSuffix(string: string, suffix: string): string {
		if (string.endsWith(suffix)) {
			return string.substring(0, string.length - suffix.length);
		}
		return string;
	}

	/**
	 * Appends the given suffix to the string in case it does not already end with it.
	 *
	 * @param string String to which the suffix should be appended if needed
	 * @param suffix Suffix that should be appended
	 * @returns The string with the suffix or the original string if it did already end in the suffix.
	 */
	public static ensureEndsWith(string: string, suffix: string): string {
		if (!string.endsWith(suffix)) {
			return string + suffix;
		}
		return string;
	}

	/**
	 * Appends the given prefix to the string in case it does not already start with it.
	 *
	 * @param string String to which the prefix should be appended if needed
	 * @param prefix Prefix that should be appended
	 * @returns The string with the prefix or the original string if it did already start with the prefix.
	 */
	public static ensureStartsWith(string: string, prefix: string): string {
		if (!string.startsWith(prefix)) {
			return prefix + string;
		}
		return string;
	}

	/**
	 * Returns the index of the last occurrence of a non-escaped forward slash.
	 *
	 * @param string String in which to search for the regex
	 * @returns The index of the last occurrence
	 */
	public static lastIndexOfUnescapedSlash(string: string): number {
		// Replaces all escaped backward slashes and forward slashes in a way
		// that the index of the remaining characters keep their positions.
		string = strings.replaceAll(string, '\\\\', '##');
		string = strings.replaceAll(string, '\\/', '##');
		return string.lastIndexOf('/');
	}

	/**
	 * Returns the start index of last occurrence of the given regular expression.
	 *
	 * @param string String with escaped characters e.g. / instead of /
	 * @returns The unescaped string
	 */
	public static unEscape(string: string): string {
		return string.replace(/\\(\/)/g, '$1');
	}

	/** @returns <code>true</code> if the given string starts with one of the given prefixes. */
	public static equalsOneOf(input: string | null, ...candidates: Array<string | null>): boolean {
		return candidates.some(candidate => candidate === input);
	}

	/**
	 * Removes everything after the last occurrence of the given separator (including the separator itself). If the
	 * separator is not found or empty, the input is returned unaltered.
	 */
	public static removeLastPart(input: string, separator: string): string {
		if (separator === '') {
			return input;
		}
		const index = input.lastIndexOf(separator);
		if (index === -1) {
			return input;
		}
		return input.substring(0, index);
	}

	/**
	 * Returns everything after the last occurrence of the given separator (excluding the separator itself). If the
	 * separator is not found or empty, the input is returned unaltered. E.g. getLastPart('some/path', '/')='path'
	 */
	public static getLastPart(input: string, separator: string): string {
		if (separator === '') {
			return input;
		}
		const index = input.lastIndexOf(separator);
		if (index === -1) {
			return input;
		}
		return input.substring(index + 1);
	}

	/**
	 * Returns everything before the first occurrence of the given separator (excluding the separator itself). If the
	 * separator is not found or empty, the input is returned unaltered. E.g. getFirstPart('some/path', '/') = 'some'
	 */
	public static getFirstPart(input: string, separator: string): string {
		if (separator === '') {
			return input;
		}
		const index = input.indexOf(separator);
		if (index === -1) {
			return input;
		}
		return input.substring(0, index);
	}

	/** Splits the string on provided separator, removes empty/whitespace strings from result. Trims non-empty strings. */
	public static splitWithWhitespaceTrim(inputString: string, separator: string): string[] {
		let result = inputString.split(separator);
		result = result.filter(inputString => !StringUtils.isEmptyOrWhitespace(inputString));
		result = result.map(inputString => inputString.trim());
		return result;
	}

	/** Decodes given UTF-8 formatted string. */
	public static decodeUTF8(string: string): string {
		return decodeURIComponent(escape(string));
	}

	/**
	 * Returns the length of the string counting unicode symbols as single characters in contrast to .length which
	 * counts unicode characters as 2 or even 4 characters.
	 */
	public static unicodeLength(str: string): number {
		return [...str].length;
	}

	/**
	 * Compare function that performs a case insensitive comparison amongst two strings.
	 *
	 * @returns A negative number, zero, or a positive number as the first argument is less than, equal to, or greater
	 *   than the second, respectively.
	 */
	public static compareCaseInsensitive(a: string, b: string): number {
		return ArrayUtils.defaultCompare(a.toLowerCase(), b.toLowerCase());
	}

	/**
	 * Cosmetically removes all occurences of '$bdroot/', '$bdroot', newlines, tabs, carriage returns and line IDs in a
	 * Simulink block identifier.
	 */
	public static transformSimulinkBlockIdentifierCosmetically(blockIdentifier: string): string {
		return blockIdentifier
			.replace(/\$bdroot\//g, '')
			.replace(/\$bdroot/g, '') // Remove all occurrences of $bdroot and $bdroot/
			.replace(/\\n/g, ' ')
			.replace(/\n/g, ' ')
			.replace(/\\t/g, ' ')
			.replace(/\t/g, ' ')
			.replace(/\\r/g, '')
			.replace(/\r/g, '')
			.replace(/\s+/g, ' ');
	}

	/** Transforms the first letter of the string to an uppercase letter */
	public static capitalize(string: string): string {
		if (string.length > 0) {
			return string.charAt(0).toUpperCase() + string.substring(1);
		}
		return string;
	}

	/**
	 * Transforms the given camel case string to a "normal" cased string, e.g. "myCamelCaseString" to "My Camel Case
	 * String".
	 */
	public static camelCaseToNormalCase(string: string): string {
		return StringUtils.capitalize(string.replace(/([a-z])([A-Z])/, (substring, args) => args[0] + ' ' + args[1]));
	}

	/** Replaces any characters that may not be part of a (Windows) file name with '-'. */
	public static toValidFileName(fileName: string): string {
		return fileName.replace(/[:\\/*"?|<>']+/g, '-');
	}

	/**
	 * @param string The string whose content will be distributed over two lines.
	 * @param maxCharactersPerLine Max length allowed for each line.
	 * @param widowThreshold If the second line would be shorter than this threshold, display truncated over a single
	 *   line instead.
	 * @returns A list with one or two elements. One element if the string fits in a single line or if it would create a
	 *   widow if split. Two elements otherwise.
	 */
	public static distributeOverTwoLines(string: string, maxCharactersPerLine: number, widowThreshold: number) {
		const originalLength = string.length;
		Assertions.assert(widowThreshold <= originalLength);

		if (originalLength <= maxCharactersPerLine) {
			return [string];
		}

		const secondLineLength = originalLength - maxCharactersPerLine;
		if (secondLineLength < widowThreshold) {
			// Too few characters on the second line: truncate beginning
			return [StringUtils.truncateBeginning(string, maxCharactersPerLine)];
		}

		if (originalLength > maxCharactersPerLine * 2) {
			// Some characters will be omitted
			return [
				truncate(string, maxCharactersPerLine),
				StringUtils.truncateBeginning(
					// Pick an extra substring character for truncate to add an ellipsis
					string.substring(originalLength - maxCharactersPerLine - 1),
					maxCharactersPerLine
				)
			];
		}

		return [string.substring(0, maxCharactersPerLine), string.substring(maxCharactersPerLine)];
	}

	/**
	 * @returns A string with length less than or equal to the provided length, with its beginning truncated and
	 *   ellipsis added if necessary.
	 */
	public static truncateBeginning(string: string, length: number) {
		// -3 accounts for length of ellipsis
		return truncateMiddle(string, length - 3, false, length - 3);
	}

	/** Adds a plural "s" to the given noun if the given count is not 1. */
	public static pluralize(count: number, noun: string) {
		return `${noun}${count !== 1 ? 's' : ''}`;
	}
}
